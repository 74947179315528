import img01 from "@content/tutorial/images/Bricklayer-amico.svg";
import img02 from "@content/tutorial/images/Completed steps-amico.svg";

import { PageLayout } from "@components";
import {
  TutorialSelector,
  TutorialSelectorItem,
} from "@components/TutorialSelector";

import "@styles/SelectionPage.scss";

export default function TutorialsIndex() {
  return (
    <PageLayout title="Tutoriels" defaultBodyClassName="features-root">
      <TutorialSelector>
        <TutorialSelectorItem
          href="/fr/tutorial/a-to-z/"
          src={img01}
          title="Concevoir une étude de A à Z"
        >
          <p>
            Ce tutoriel est destiné aux utilisateurs souhaitant mettre en place
            une étude et construire un eCRF à partir de zéro.
          </p>
        </TutorialSelectorItem>

        <TutorialSelectorItem
          href="/fr/tutorial/using-dotter/"
          src={img02}
          title="Utiliser Dotter.science au quotidien"
        >
          <p>
            Si vous êtes un utilisateur d'une étude déjà créée, ce tutoriel va
            vous apprendre à utiliser l'eCRF mis en place.
          </p>
        </TutorialSelectorItem>
      </TutorialSelector>
    </PageLayout>
  );
}
